import { useState } from "react";
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import CloseButton from 'react-bootstrap/CloseButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from "react";

interface OtherVictoryModalProps {
  onVictoryClose: ()=>void;
  showModal: boolean;
}

function OtherVictoryModal(props: OtherVictoryModalProps) {
  const [showToast, setShowToast] = useState(false);
  
  const width = Math.min(window.innerHeight, window.innerWidth) * 0.05;
  
  const copyResults = () => {
    navigator.clipboard.writeText(
      `❤️❤️❤️\n` + 
      `❤️❤️❤️\n` + 
      `❤️❤️❤️\n` + 
      `Thanks dude\n `
    );
    setShowToast(true);
  }

  return (
    <>
      <Modal
        show={props.showModal}
        className='victory-modal'
        size='sm'
        animation={true}
      >
        <div className="close-button-container">
          <CloseButton onClick={() => props.onVictoryClose()} />
        </div>
        <Modal.Body style={{marginTop: width}}>
          <div style={{fontSize: 40, fontWeight: 'bold'}}> {process.env.REACT_APP_PUZZLI_SPECIAL_HEADING} </div>
          <div style={{fontSize: 22, marginTop: 10}}> {process.env.REACT_APP_PUZZLI_SPECIAL_MESSAGE} </div>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="primary"
            onClick={() => copyResults()} 
            style={{margin: 15, width: 120}}>
            Say Thanks
          </Button>
        </Modal.Footer>
        <ToastContainer position="middle-center">
          <Toast show={showToast} onClose={() => setShowToast(false)} delay={2000} autohide>
            <Toast.Body>Thanks copied to clipboard</Toast.Body>
          </Toast>
        </ToastContainer>
      </Modal>
    </>
  );
}

export default OtherVictoryModal;